import React, { useRef } from "react";
import { Link } from "gatsby";
import SEO from "@components/seo";
import { Div } from "@components/Markup";
import { css } from "@emotion/react";
import styled from "styled-components";
import { Title } from "@components/Typography";
import { gsap, ScrollTrigger } from "gsap/all";
import SplitText from "../plugins/SplitText";
import HomepageVideo from "@components/HomepageVideo";
import HomepageSlider from "@components/HomepageSlider";
import Icon from "@components/Icon";
gsap.registerPlugin(ScrollTrigger, SplitText);

// 1. Vertrauen und Wertschätzung sind unsere Motivation
// 2. Wir überzeugen durch unsere Kompetenz
// 3. Gemeinsame Arbeit für bessere Lebensräume bereitet uns Freude
// 4. Für uns ist Kreativität die Synthese aus Know-How und Ästhetik
// 5. Effizienz bedeutet für uns nachhaltig und wirtschaftlich zu agieren
// >Menschliches Handeln, Kraftvolle Ideen, Effiziente Arbeit
// > 1 + 1 = 3 (more than the sum of its parts - note for me)

const NavItem = ({ text, url }) => {
  return (
    <Link
      to={url}
      css={css`
        align-items: center;
        max-width: 420px;
        //padding-right: 24px;
        position: relative;
        display: inline-flex;
        margin-right: 24px;
        border-bottom: 2px solid transparent;

        svg {
          position: absolute;
          right: 8px;
          transition: 0.25s all;
          display: none;
        }

        &:hover {
          border-color: white;
          svg {
            right: 4px;
          }
        }

        @media (min-width: 768px) {
          margin-right: 48px;
        }
      `}
    >
      <Div>
        <Title
          tag="h2"
          size="sm"
          uppercase
          css={css`
            text-transform: uppercase;

            @media (max-width: 1199px) {
              font-size: 18px !important;
            }
          `}
        >
          {text}
        </Title>
      </Div>
    </Link>
  );
};

const Heading = styled.h1`
  font-family: "noah-heavy";
  font-size: 48px;
  line-height: 1em;

  @media (min-width: 768px) {
    font-size: 64px;
  }
`;

const IndexPage = ({ data }) => {
  const headingRef = useRef(null);
  const headingContainerRef = useRef(null);
  const sliderContainerRef = useRef(null);
  const { datoCmsKarrierenSeite } = data;
  const { showOnHomepage, showOnHomepageSlug } = datoCmsKarrierenSeite;
  console.log(showOnHomepage, "URL", showOnHomepageSlug);
  return (
    <React.Fragment>
      <SEO
        title={`Hawlik Gerginski Architekten`}
        description={`Hawlik Gerginski Architekten | Startseite`}
        image={"https://www.datocms-assets.com/39001/1630416360-img33751.jpg"}
      />
      <div
        css={css`
          position: relative;
          display: flex;
          align-items: flex-end;
          height: calc(100vh - 64px);
          @media (min-width: 768px) {
            height: calc(100vh - 128px);
          }
          @media (min-width: 768px) {
            height: calc(100vh - 136px);
          }
        `}
      >
        <Div
          css={css`
            position: fixed;
            top: 64px;
            left: 0;
            width: 100%;
            z-index: -1;
            height: calc(100vh - 64px);
            @media (min-width: 992px) {
              height: calc(100vh - 128px);
            }
          `}
        >
          <HomepageVideo />
        </Div>
        <Div
          ref={headingContainerRef}
          css={css`
            color: white;
            z-index: 10;
            position: absolute;
            height: 100%;
            width: 100%;
            padding: 64px 24px;

            @media (min-width: 768px) {
              padding: 64px 48px;
            }
          `}
        >
          <Heading
            ref={headingRef}
            dangerouslySetInnerHTML={{ __html: `Bessere<br />Lebensräume` }}
          />
        </Div>
        <Div
          ref={sliderContainerRef}
          css={css`
            z-index: 20;
            color: white;
            /* max-width: 800px; */
            width: 100%;
            position: absolute;
            bottom: 0;
            padding: 64px 24px 0px;

            @media (min-width: 768px) {
              padding: 64px 48px 0px;
            }
          `}
        >
          <Div mb="lg">
            <HomepageSlider />
          </Div>
          <Div
            css={css`
              display: flex;
              justify-content: space-between;

              @media (max-width: 767px) {
                flex-direction: column;
                padding-bottom: 24px;
              }

              @media (max-width: 991px) and (max-height: 600px) {
                display: none;
              }
            `}
          >
            <Div
              css={css`
                display: flex;

                @media (max-width: 767px) {
                  margin-bottom: 24px;
                }
              `}
            >
              <NavItem text="Impulse" url="/blog" />
              <NavItem text="Projekte" url="/projekte" />
              <NavItem text="Über uns" url="/uber-uns" />
            </Div>
            {showOnHomepage && (
              <NavItem
                text="Deine Karriere bei Uns"
                url={
                  showOnHomepageSlug
                    ? `/karriere/${showOnHomepageSlug}`
                    : `/karriere`
                }
              />
            )}
          </Div>
        </Div>
      </div>
    </React.Fragment>
  );
};

export default IndexPage;

export const query = graphql`
  query HomepageQuery {
    datoCmsKarrierenSeite {
      showOnHomepage
      showOnHomepageSlug
    }
  }
`;

import React, { useEffect, useRef, useContext } from "react";
import { css } from "@emotion/react";
import { Div } from "./Markup";
import Player from "@vimeo/player";
import { ResizeContext } from "@providers/ResizeProvider";

const VideoBackground = () => {
  let pid = "663427448";
  const videoProportion = 1.78;
  const MULT = 1.1;
  const videoContainerRef = useRef(null);
  const playerRef = useRef(null);
  const { addResizeCallback } = useContext(ResizeContext);

  const initVideo = () => {
    const screenProportion = window.innerWidth / window.innerHeight;

    let vw, vh;
    if (screenProportion < videoProportion) {
      vh = window.innerHeight;
      vw = videoProportion * vh;
    } else {
      vw = window.innerWidth;
      vh = vw / videoProportion;
    }

    if (playerRef.current) {
      playerRef.current.destroy();
    }
    if (videoContainerRef.current) {
      playerRef.current = new Player(videoContainerRef.current, {
        id: pid,
        title: false,
        controls: false,
        byline: false,
        background: true,
        autoplay: true,
        muted: true,
        loop: true,
        width: vw * MULT,
        height: vh * MULT,
      });
    }
  };

  useEffect(() => {
    initVideo();

    return addResizeCallback(initVideo);
  }, []);

  return (
    <Div
      css={css`
        height: 100%;
        width: 100%;
        position: relative;
      `}
    >
      <Div
        css={css`
          min-height: 110vh;
          min-width: 110vw;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(-20px, -50%, 0);
        `}
      >
        <div
          ref={videoContainerRef}
          css={css`
            /* min-height: 120vh;
            min-width: 120vw; */
            position: absolute;
            top: 0;
            left: 0;
            /* transform: translate3d(-50%, -50%, 0); */
          `}
        />
        {/* <ReactPlayer
          url={kng}
          muted={true}
          playing={false}
          loop={true}
          width="auto"
          height="auto"
        /> */}
      </Div>
    </Div>
  );
};

export default VideoBackground;
